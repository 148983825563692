var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0"},[(_vm.loading)?_c('v-container',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"orange","indeterminate":""}})],1)]):_vm._e(),(!_vm.loading)?_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"id":"answersMap"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"80vh"},attrs:{"center":{lat:this.currentLat, lng:this.currentLng},"zoom":7,"map-type-id":"roadmap","options":{
          zoomControl: true,
          zoom: 11,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false
        }}},[_vm._l((_vm.blocks),function(m,index){return _c('GmapCircle',{key:index,attrs:{"center":{lat: m.center.lat, lng: m.center.lng},"radius":(250+(1500*m.pct)),"options":{
            fillColor: m.color,
            fillOpacity: 0.8,
            strokeColor: m.color,
            strokeOpacity: 0.8,
            strokeWeight: 2
          }}})}),_c('GmapCircle',{attrs:{"center":{lat: _vm.currentLat, lng: _vm.currentLng},"radius":8000,"options":{
            fillColor:'blue',
            fillOpacity:0.1,
            strokeColor: '#0000FF',
            strokeOpacity: 0.2,
            strokeWeight: 5
          }}})],2)],1):_vm._e(),_c('v-container',{staticClass:"pa-0 ma-0"},[(_vm.$auth.isAuthenticated)?_c('v-btn',{staticClass:"mb-4",attrs:{"fixed":"","dark":"","fab":"","bottom":"","right":"","color":"gray","to":"/"}},[_c('v-icon',[_vm._v("arrow_back")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }