var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0"},[(!_vm.$auth.isAuthenticated)?_c('div',{attrs:{"id":"unauth-view"}},[_c('v-card',{staticClass:"ma-2"},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"grey"}}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline"},[_vm._v("Welcome!")]),_c('v-list-item-subtitle',[_vm._v("Share local knowledge")])],1)],1),_c('v-img',{attrs:{"src":require("@/assets/overview.jpg"),"height":"194"}}),_c('v-card-text',[_vm._v(" Who serves the best coffee? Where's the best local park? Get the answers to these questions and more from a local audience! ")])],1)],1):_vm._e(),(_vm.$auth.isAuthenticated)?_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"id":"auth-view"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{lat:this.currentLat, lng:this.currentLng},"zoom":7,"map-type-id":"roadmap","options":{
        zoomControl: true,
        zoom: 11,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false
      }}},[_vm._l((_vm.questions),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":{lat: m.latitude, lng: m.longitude},"clickable":false,"draggable":false},on:{"click":function($event){_vm.center=m.position}}})}),_c('GmapCircle',{attrs:{"center":{lat: _vm.currentLat, lng: _vm.currentLng},"radius":8000,"options":{
          fillColor:'blue',
          fillOpacity:0.1,
          strokeColor: '#0000FF',
          strokeOpacity: 0.2,
          strokeWeight: 5
        }}})],2)],1):_vm._e(),(_vm.$auth.isAuthenticated && _vm.loading)?_c('v-container',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"orange","indeterminate":""}})],1)]):_vm._e(),(_vm.questions.length === 0 && !_vm.loading)?_c('v-container',[_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("No questions here")]),_c('v-card-subtitle',[_vm._v("Whoa! This is a quiet neighborhood. Be the first to ask a question.")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"orange","text":""}},[_vm._v("Ask question")])],1)],1)],1):_vm._e(),(_vm.questions.length > 0)?_c('v-container',{staticClass:"pa-0 ma-0"},_vm._l((_vm.questions),function(question,index){return _c('v-container',{key:index,staticClass:"pa-0 ma-0"},[_c('Question',{attrs:{"question":question}})],1)}),1):_vm._e(),_c('v-container',{staticClass:"pa-0 ma-0"},[(_vm.$auth.isAuthenticated)?_c('v-btn',{staticClass:"mb-4",attrs:{"fixed":"","dark":"","fab":"","bottom":"","right":"","color":"red","to":"/ask"}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }